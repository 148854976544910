import { useCallback, useContext, useMemo, useState } from 'react'
import BookingProgress from '../components/booking-progress'
import { useTranslation } from 'react-i18next'
import { DepartureContext } from '../contexts/departure'
import Loader from '../components/loader'
import { Cabin } from '../services/departures'
import { useCurrencyFormatter } from '../hooks/formatter'
import NumberSelect from '../components/number-select'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ChevronDown, ChevronUp } from 'react-feather'
import CabinIcon from '../assets/cabin-icon.svg'
import { useCabinSelection, useFullCabinSelection } from '../hooks/cabin-selection'
import StepperInput from '../components/stepper-input'
import { CabinsContext } from '../contexts/cabins'
import { Disclosure } from '@headlessui/react'
import { usePreloadQuery } from '../hooks/preload'
import { ProductTranslationsQuery } from '../queries/translations'
import Sidebar from '../components/sidebar'
import TwoColumnLayout from '../layouts/columns'
import { cleanHTML } from '../utils/html'
import { gtmAddCabin, gtmRemoveCabin } from '../utils/gtm'
import { WithError } from '../components/with-error'
import { useFrameDetector } from '../hooks/frame-detector'
import { useBrandBasePath } from '../hooks/brand'
import { z } from 'zod'

function CabinCard ({ cabin }: { cabin: Cabin }): JSX.Element {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const defaultAmount = cabin.minimum_occupancy
  const { passengers, setPassengers, setCount } = useCabinSelection(cabin.id, defaultAmount)

  const changeCount = useCallback((newCount: number) => {
    setCount(newCount)
    const difference = newCount - passengers.length
    if (difference > 0) {
      gtmAddCabin(cabin, difference, 2)
    } else {
      passengers.slice(newCount).forEach(passenger => {
        gtmRemoveCabin(cabin, 1, passenger)
      })
    }
  }, [cabin, passengers, setCount])

  const changePassengers = useCallback((index: number, newAmount: number) => {
    const oldAmount = passengers[index]
    setPassengers(index, newAmount)
    gtmRemoveCabin(cabin, 1, oldAmount)
    gtmAddCabin(cabin, 1, newAmount)
  }, [cabin, passengers, setPassengers])

  return (
    <div className='bg-brand-well p-4 rounded mb-8 last:mb-24'>
      <Disclosure defaultOpen={import.meta.env.DEV}>
        {({ open }) => (
          <>
            <div className='flex gap-8 flex-wrap md:flex-nowrap'>
              <figure className='mb-4'>
                <img src={cabin.image_url ?? CabinIcon} alt={cabin.name} className='rounded w-full md:w-72 aspect-[7/5] object-cover' />
              </figure>
              <div className='flex-1'>
                <p className='text-sm font-bold'>
                  {t('only_x_left', 'Only {{ count }} left', { count: cabin.count })}
                </p>
                <h2 className='text-base font-bold mt-4 mb-2'>{cabin.name}</h2>
                {cabin.minimum_occupancy === 1 && (<h3 className='text-base mb-2 -mt-2'>{t('available_for_single_use', 'Available for single use')}</h3>)}
                {cabin.description !== undefined && (
                  <Disclosure.Button className='text-brand-primary mb-4'>
                    {open ? t('read_less', 'Read less') : t('read_more', 'Read more')}
                    {open ? <ChevronUp className='inline-block' size={16} strokeWidth={3} /> : <ChevronDown className='inline-block' size={16} strokeWidth={3} />}
                  </Disclosure.Button>
                )}
              </div>
              <div className='flex flex-col gap-4 items-end'>
                <NumberSelect min={0} max={cabin.count} value={passengers.length} onChange={changeCount} translation='cabin_count' variant='white' className='min-w-[10rem]' />
                <div className='text-lg font-bold'>
                  {t('price_per_person', '{{ price }}\u00A0p.p.', { price: currencyFormatter.format(cabin.unit_price) })}
                </div>
              </div>
            </div>
            <Disclosure.Panel className='mb-4'>
              <div className='' dangerouslySetInnerHTML={{ __html: cleanHTML(cabin.description ?? '') }} />
              {cabin.image_urls !== undefined && cabin.image_urls.length > 1 && (
                <div className='flex gap-4 overflow-y-auto mt-4'>
                  {cabin.image_urls.map((url, index) => (
                    <img src={url} alt={`Image ${index} of the cabin`} key={index} className='aspect-[7/5] object-cover rounded h-[12rem]' />
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <hr className='border-secondary-300' />
      <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-8 pt-8'>
        {passengers.map((passenger, index) => (
          <div key={index}>
            <div className='flex justify-between text-xs mb-2 gap-2'>
              <span className='text-brand-primary whitespace-nowrap'>{t('cabin_x', 'Cabin {{ index }}', { index: index + 1 })}</span>
              <span className='text-secondary-600 whitespace-nowrap'>{t('max_x_persons', 'max {{ count }} persons', { count: cabin.maximum_occupancy })}</span>
            </div>
            <StepperInput block value={passenger} min={cabin.minimum_occupancy} max={cabin.maximum_occupancy} onValueChange={(value) => changePassengers(index, value)} />
          </div>
        ))}
      </div>
    </div>
  )
}

function zeroToUndefined<T extends number> (a: T): T | undefined {
  return a === 0 ? undefined : a
}

function compareNumbers (a: number, b: number): 0 | -1 | 1 {
  return a > b ? 1 : (a < b ? -1 : 0)
}

export default function SelectCabins (): JSX.Element {
  const { departure } = useContext(DepartureContext)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { cabins } = useContext(CabinsContext)
  const frame = useFrameDetector()
  const basePath = useBrandBasePath()
  const [showError, setShowError] = useState(false)
  const [searchParams] = useSearchParams()
  const partySize = z.coerce.number().nullable().parse(searchParams.get('party'))

  const cabinTypeIds = useMemo(() => (cabins ?? []).map(cabin => cabin.id), [cabins])
  const sortedCabins = useMemo(() => {
    return cabins?.toSorted((a, b) => {
      const priceMultiplier = ['nl', 'de'].includes(i18n.resolvedLanguage ?? 'en') ? 1 : -1
      return zeroToUndefined(compareNumbers(b.minimum_occupancy, a.minimum_occupancy)) ??
        (compareNumbers(a.unit_price, b.unit_price) * priceMultiplier)
    })
  }, [cabins, i18n.resolvedLanguage])

  const selectedCabins = useFullCabinSelection(cabinTypeIds)
  const cabinPassengers = useMemo(() => Object.values(selectedCabins).flat(), [selectedCabins])
  const totalPassengers = useMemo(() => cabinPassengers.reduce((acc, curr) => acc + curr, 0), [cabinPassengers])

  const handleBack = useCallback(() => {
    navigate(`../../tours/${departure.tour_id}/date/${departure.date}${partySize != null ? `?party=${partySize}` : ''}`)
  }, [departure.date, departure.tour_id, navigate, partySize])

  const handleNext = useCallback(() => {
    const rooms = Object.entries(selectedCabins).map(([key, value]) => [key, value.join(',')])
    if (totalPassengers === 0) {
      setShowError(true)
      return
    }
    const cabins = new URLSearchParams(Object.fromEntries(rooms))
    const params = new URLSearchParams({ cabins: cabins.toString(), locale: i18n.resolvedLanguage ?? 'en' })
    const nextURL = `${basePath}/departures/${departure.id}/passengers?${params.toString()}`
    if (frame) {
      window.open(nextURL)
    } else {
      navigate(nextURL)
    }
  }, [basePath, departure.id, frame, i18n.resolvedLanguage, navigate, selectedCabins, totalPassengers])

  usePreloadQuery(ProductTranslationsQuery())

  return (
    <>
      <BookingProgress currentStep={3} />
      <TwoColumnLayout>
        <section>
          <h2 className='text-brand-primary text-lg font-bold mb-4'>{t('select_your_cabins', 'Please select your cabin(s)')}</h2>
          {sortedCabins === undefined
            ? (<Loader />)
            : (
                sortedCabins.length > 0
                  ? (
                    <WithError
                      anchor='top'
                      error={showError ? t('select_your_cabins', 'Please select your cabin(s)') : null}
                      clearError={() => setShowError(false)}
                    >{sortedCabins.map(cabin => <CabinCard
                      key={cabin.id}
                      cabin={cabin}
                                                />)}
                    </WithError>
                    )
                  : (<p>{t('no_cabins_available', 'No cabins available')}</p>)
              )}
        </section>
        <Sidebar
          onBack={handleBack}
          onNext={handleNext}
          nextLabel={<><span className='hidden frame:inline'>{t('start_booking', 'Start booking')}</span><span className='frame:hidden'>{t('next', 'Next')}</span></>}
        />
      </TwoColumnLayout>
    </>
  )
}
